.cci-preview {
    max-width: 300px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin: 10px 0px;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    height: 400px;
    background-color: #ffffff;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    border: #ced4da 1px solid;
}
